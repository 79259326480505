export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'itemCode',
    label: 'field.itemNo',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'name',
    label: 'field.description',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'requestSectionName',
    label: 'field.requestSection',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'stockLocationName',
    sortable: true,
    sortField: 'stockLocationId',
    label: 'field.location',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'stockBinName',
    label: 'field.binCode',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'quantity',
    label: 'field.quantity',
  },
  {
    key: 'type',
    label: 'field.type',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'remark',
    label: 'field.remark',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.createDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
];
